import React from "react"
import Menu from "./Menu"

const Header = () => {
  return (
    <>
      <header>
        <div>
          <Menu />
        </div>
      </header>
      <div id="banner"></div>
    </>
  )
}

export default Header
