/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../styles/index.css';
import Layout from "../components/Layout";
import '@brainhubeu/react-carousel/lib/style.css';
import {useForm} from 'react-hook-form';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import emailjs from 'emailjs-com';

const Contacts = () => {
    const {register, handleSubmit, reset, formState: {errors}} = useForm();
    const toastifySuccess = () => {
        toast('Сообщение отправлено!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            className: 'submit-feedback success',
            toastId: 'notifyToast'
        });
    };
    const onSubmit = async (data) => {
        try {
            const templateParams = {
                name: data.secondName + " " + data.firstName,
                email: data.email,
                country: data.country,
                phone: data.phone,
                company: data.company,
                message: data.message
            };
            await emailjs.send(
                'bongard_xbklw3s',
                'bongard_contactus_form',
                templateParams,
                'user_56H7iq2kC1reM9GmEzcZC'
            );
            toastifySuccess();
            reset();
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <Layout location={"/contact-us"} type={"boulangeries"}>
            <ToastContainer/>
            <div>
                <div className="ctr"><h1>Связаться с нами</h1></div>
                <div className="small_content">
                    <p style={{"textAlign": "center"}}></p>
                    <p>&nbsp;</p>
                    <div className="source_url_spacer"></div>
                    <div role="form" className="wpcf7" id="wpcf7-f1498-o1" lang="fr-FR" dir="ltr">
                        <div className="screen-reader-response" aria-live="polite"/>
                        <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate className="wpcf7-form">
                            <div id="form_wrapper">
                                <div className="double">
                                    <div><label>Фамилия *</label>
                                        <div className="form-group cf-prenom">
                                            <input
                                                {...register('secondName', {
                                                    required: true
                                                })}
                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                name="secondName" type="text" aria-invalid="false"
                                                aria-required="true" required=""/>
                                            {errors.secondName &&
                                            <span className='errorMessage'>Пожалуйста, введите вашу фамилию</span>}
                                        </div>
                                    </div>
                                    <div><label>Имя *</label>
                                        <div className="form-group cf-nom">
                                            <input
                                                {...register('firstName', {
                                                    required: true
                                                })}
                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                name="firstName" type="text" aria-invalid="false"
                                                aria-required="true" required=""/>
                                            {errors.firstName &&
                                            <span className='errorMessage'>Пожалуйста, введите ваше имя</span>}
                                        </div>
                                    </div>
                                    <div><label>E-mail *</label>
                                        <div className="form-group cf-email">
                                            <input
                                                {...register('email', {
                                                    required: true,
                                                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                                })}
                                                className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control"
                                                name="email" type="email" aria-invalid="false"
                                                aria-required="true" required=""/>
                                            {errors.email && (
                                                <span
                                                    className='errorMessage'>Пожалуйста, введите правильный email</span>
                                            )}
                                        </div>
                                    </div>
                                    <div><label>Телефон *</label>
                                        <div className="form-group cf-telephone">
                                            <input
                                                {...register('phone', {
                                                    required: true
                                                })}
                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                name="phone" id="phone" type="text" aria-invalid="false"
                                                aria-required="true" required=""/>
                                            {errors.firstName &&
                                            <span
                                                className='errorMessage'>Пожалуйста, введите ваше телефон для связи</span>}
                                        </div>
                                    </div>
                                    <div><label>Страна</label>
                                        <div className="form-group cf-pays">
                                            <input
                                                {...register('country', {
                                                    required: false
                                                })}
                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                name="country" type="text" aria-invalid="false"
                                                aria-required="true" required=""/>
                                        </div>
                                    </div>
                                    <div><label>Компания</label>
                                        <div className="form-group cf-societe">
                                            <input
                                                {...register('company', {
                                                    required: false
                                                })}
                                                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                                name="company" type="text" aria-invalid="false"
                                                aria-required="true" required=""/>
                                        </div>
                                    </div>
                                </div>
                                <label>Сообщение *</label>
                                <div className="form-group your-message">
                                        <textarea id="message"
                                                  {...register('message', {
                                                      required: true
                                                  })}
                                                  className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control"
                                                  name="message" rows="4" aria-invalid="false" aria-required="true"
                                                  required=""/>
                                    {errors.message &&
                                    <span className='errorMessage'>Пожалуйста, введите сообщение</span>}

                                </div>
                                <div className="form-group">
                                    <div>
                                        <input className="btn btn-primary" type="submit" value="Отправить"/>
                                        <span className="ajax-loader"/>
                                    </div>
                                </div>
                            </div>
                            <div className="wpcf7-response-output wpcf7-display-none alert"/>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Contacts;
