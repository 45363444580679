/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../styles/index.css';
import siteMap from '../data/sitemap.json';
// Components
import Header from './../components/Header'
import Footer from './../components/Footer'
import {Link} from "gatsby";
import Seo from "../components/Seo";


const Layout = ({children, seo, location, type}) => {
    let parents = [];
    let parent = location.replace(/\/[^\/]+$/, '');
    while (parent !== "") {
        parents.push(parent);
        parent = parent.replace(/\/[^\/]+$/, '');
    }
    parents = parents.reverse();
    return (
        <div id="ar-global">
            <Seo title={"Bongard - " + siteMap[location]}
                 description={seo && seo.description ? seo.description : "Хлебопекарное оборудование Bongard"}/>
            <div id="ar-page">
                <Header/>
                <main>
                    <div id="breadcrumb">
                        <div>
                            <span>
                                <span><Link to={"/"}>Главная</Link></span>
                                {
                                    parents.map(parent => {
                                        return (
                                            <span key={"parent_" + parent.replace("/", "")}><span>  |  </span><Link
                                                to={parent}>{siteMap[parent]}</Link></span>
                                        )
                                    })
                                }
                                <span><span>  |  </span><Link to={location}>{siteMap[location]}</Link></span>
                            </span>
                        </div>
                    </div>
                    <section id={type ? type : "produits"} className="content">
                        {children}
                    </section>
                </main>
                <Footer/>
            </div>
        </div>
    )
}

export default Layout;
